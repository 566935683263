body {
  margin: 0;
  font-family: 'Spartan' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px !important;
  padding-top: 56px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h3{
  font-size: 18px !important;
  font-weight: bold !important;
}

label {
  font-size: 12px !important;
}

button {
  font-size: 12px !important;
}

.form-control{
  font-size: 12px !important;
}

th{
  font-size: 12px;
}

td{
  font-size: 12px;
}

@media only screen and (min-width: 481px) {
  main{
    margin-top:90px;
  }
}

@media only screen and (max-width: 480px) {
  main{
    margin-top:-15px;
  }
}
.menubar {
  overflow: hidden;
  width: 100%;
  background-color: white;
  position: absolute;
  top:0;
  z-index: 100;
}

.header{
  padding:10px 0px;
  
}

.header .container{
  display: flex;
}

.header img{
  padding: left 10px;
  height: 80px;
}

.header-title{
  font-size: 24px;
  margin-top:20px;
  margin-left: 20px;
}

.menubar .menu-items {
  background-image: linear-gradient(rgba(0,0,0,.6196078431372549) -330%,#007B39 46%);
  /* background-color:#1C4175; */
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px;
  box-sizing: border-box;
  /* border-bottom: #ddd  solid 1px; */
  text-transform: uppercase;
}

.menubar .menu-items .container {
  height: 25px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;

}
.menubar .menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-weight: bold;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  font-size: 12px;
  color: white;
}

.menubar .menu-item:hover {
  border-bottom: 2px solid white;
}



.header-mobile{
  overflow: hidden;
  width: 100%;
  position: fixed;
  top: 0;
  /* padding: 0px 10px; */
  z-index: 100;
  background-color: #007B39;
}

.header-mobile-menu-button {
  padding: 10px;
}
.header-mobile-menu-button svg {
  fill: #fff;
  height: 24px;
}

.header-mobile-businessname {
  display: inline-block;
  padding-top: 12px;
  font-size: 14px;
  color: #fff;
}

.menu-overlay {
  background: rgba(103, 118, 140, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  cursor: default;
}

.mobile-menu-hide {
  height: 100%;
  width: 80%;
  position: fixed;
  z-index: 3000;
  left: 0;
  top: 0;
  background-color: white;
  overflow: auto;
  max-width: 300px;
  transform: translate3d(-100vw, 0, 0);
  transition: transform .3s cubic-bezier(0, .52, 0, 1);
}

.mobile-menu-show{
  height: 100%;
  width: 80%;
  position: fixed;
  z-index: 3000;
  left: 0;
  top: 0;
  background-color: white;
  overflow: auto;
  max-width: 300px;
  transform: translate3d(0vw, 0, 0);
  transition: transform .3s cubic-bezier(0, .52, 0, 1);
}

.menu-logo{
  display:flex;
  justify-content:center;
  padding:10px;
}

.menu-content{
  border-top: solid 1px #ddd;
}

.menu-logo img{ 
  /* width:40%; */
  height:72px;
}

.menu-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  padding: 10px 0px;
  padding-left: 10px;
}
.seperator {
  border-bottom: 1px solid #dee3e7;
}
.menu-row-text {
  font-size: 12px;
  width: 90%;
  padding-left: 0px;
  color: #101928;
}


.footer{
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: white;
    /* color: white; */
    text-align: center;
}

.footer button{
  width: 50%;
}

.window{
  position: fixed;
  top: 0;
  left: 0;
  /* transform: translate(-50%, -50%); */
  width:100%;
  height:100%;
  background-color:white; 
  z-index: 5000;
}

.window-header{
  position: fixed;
  width: 100%;
  display: flex;
  /* border-bottom: 1px solid #dee3e7; */
  /* background-color: white; */
  z-index: 5001;
  padding:10px;
  font-size: 14px;
  color: white;
  background-color:#1C4175;
}

.window-close-btn{
  position: absolute;
  top:8px;
  right: 5px;
}

.window-close-btn svg{
  fill:white;
  width: 12px;
}

.window-content{
  margin-top:50px;
  padding: 10px;
  overflow: auto;
  height: 100%;
  position: fixed;
  width: 100%;
}

.window-footer{
  width: 100%;
  padding: 10px;
  background-color: white;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
}

.window-footer button{
  width: 50%;
  /* margin:10px; */
}

.machine{
  border: solid 1px #ddd;
  border-radius: 10px;
  padding:10px;
  margin-bottom: 10px;
  font-size: 12px;
}

.machine-footer{
  display: flex;
  justify-content: center;
}
.machine-footer button{
  width:50%;
  background-color: #ddd;
}
.machine-grand-total{
  font-size: 12px
}

.transaction{
  border: solid 1px #ddd;
  border-radius: 10px;
  padding:10px;
  margin-bottom: 10px;
  font-size: 12px;
}
.transaction-machine{
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
}
.transaction-footer a{
  background-color: #ddd;
  width: 100%;
}

.login{
  background-color: white;
  height: 500px;
  padding:10px;
  margin:0px 10px;
}

.login button{
  width:100%;
  margin-bottom: 10px;
}

.text-disclaimer{
  text-align: right;
  padding-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
}

.mt20{
  margin-top: 20px;
}
.mb40{
  margin-bottom: 40px;
}

.form-subscriber .req{
  color: red;
  font-size: 1.25em;
  font-family: Arial, Helvetica, sans-serif;
}
.form-subscriber .form-subscriber-label{
  margin-top: 20px;
  padding-bottom: 5px;
}

select.form-control{
  appearance: auto;
}
select.form-control option{
  padding: 5px 0;
}


.title-page .icon{
  margin-right: 10px;
  font-size: 20px;
}

.mr10{margin-right: 10px;}
.page-add-subscription .help-block{padding-top: 5px;font: 0.875rem arial;}
.txt-no-record{padding: 10px; text-align: center;}
.text-right{text-align: right;}
.has-error .quill{border: 2px solid #dd4b39;}
.ql-container {height: auto !important;}
.btn-subscription{font:0.875rem arial; padding: 5px 0; border-radius: 5px;border: 1px solid rgba(0, 0, 0, 0.3)}
.ql-editor {
  min-height: 18em;
}

.upload-file{
  border: 2px solid #007B39;
  width: 500px;
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 15px;
  margin-top: 150px;
}

.show-file{
  display: flex;
  justify-content: space-between;
  width:  500px;
  margin: auto;
}